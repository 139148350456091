export interface ILangmap {
  [key: string]: string
}

export const langmap: ILangmap = {
  ach: 'Lwo',
  ady: 'Адыгэбзэ',
  af: 'Afrikaans',
  'af-NA': 'Afrikaans (Namibia)',
  'af-ZA': 'Afrikaans (South Africa)',
  ak: 'Tɕɥi',
  ar: 'العربية',
  'ar-AR': 'العربية',
  'ar-MA': 'العربية',
  'ar-SA': 'العربية (السعودية)',
  'ay-BO': 'Aymar aru',
  az: 'Azərbaycan dili',
  'az-AZ': 'Azərbaycan dili',
  'be-BY': 'Беларуская',
  bg: 'Български',
  'bg-BG': 'Български',
  bn: 'বাংলা',
  'bn-BD': 'বাংলা(বাংলাদেশ)',
  'bn-IN': 'বাংলা (ভারত)',
  'bs-BA': 'Bosanski',
  ca: 'Català',
  'ca-ES': 'Català',
  cak: 'Maya Kaqchikel',
  'ck-US': 'ᏣᎳᎩ (tsalagi)',
  cs: 'Čeština',
  'cs-CZ': 'Čeština',
  cy: 'Cymraeg',
  'cy-GB': 'Cymraeg',
  da: 'Dansk',
  'da-DK': 'Dansk',
  de: 'Deutsch',
  'de-AT': 'Deutsch (Österreich)',
  'de-CH': 'Deutsch (Schweiz)',
  'de-DE': 'Deutsch (Deutschland)',
  dsb: 'Dolnoserbšćina',
  el: 'Ελληνικά',
  'el-GR': 'Ελληνικά',
  en: 'English',
  'en-AU': 'English (Australia)',
  'en-CA': 'English (Canada)',
  'en-GB': 'English (UK)',
  'en-IE': 'English (Ireland)',
  'en-IN': 'English (India)',
  'en-PI': 'English (Pirate)',
  'en-UD': 'English (Upside Down)',
  'en-US': 'English (US)',
  'en-ZA': 'English (South Africa)',
  'en@pirate': 'English (Pirate)',
  eo: 'Esperanto',
  'eo-EO': 'Esperanto',
  es: 'Español',
  'es-419': 'Español (Latinoamérica)',
  'es-AR': 'Español (Argentine)',
  'es-CL': 'Español (Chile)',
  'es-CO': 'Español (Colombia)',
  'es-EC': 'Español (Ecuador)',
  'es-ES': 'Español (España)',
  'es-LA': 'Español (Latinoamérica)',
  'es-MX': 'Español (México)',
  'es-NI': 'Español (Nicaragua)',
  'es-US': 'Español (Estados Unidos)',
  'es-VE': 'Español (Venezuela)',
  et: 'eesti keel',
  'et-EE': 'Eesti (Estonia)',
  eu: 'Euskara',
  'eu-ES': 'Euskara',
  fa: 'فارسی',
  'fa-IR': 'فارسی',
  'fb-LT': 'Leet Speak',
  ff: 'Fulah',
  fi: 'Suomi',
  'fi-FI': 'Suomi',
  'fo-FO': 'Føroyskt',
  fr: 'Français',
  'fr-BE': 'Français (Belgique)',
  'fr-CA': 'Français (Canada)',
  'fr-CH': 'Français (Suisse)',
  'fr-FR': 'Français (France)',
  'fy-NL': 'Frysk',
  ga: 'Gaeilge',
  'ga-IE': 'Gaeilge (Gaelic)',
  gl: 'Galego',
  'gl-ES': 'Galego',
  'gn-PY': 'Avañe\'ẽ',
  'gu-IN': 'ગુજરાતી',
  'gx-GR': 'Ἑλληνική ἀρχαία',
  he: 'עברית‏',
  'he-IL': 'עברית‏',
  hi: 'हिन्दी',
  'hi-IN': 'हिन्दी',
  hr: 'Hrvatski',
  'hr-HR': 'Hrvatski',
  hsb: 'Hornjoserbšćina',
  ht: 'Kreyòl',
  hu: 'Magyar',
  'hu-HU': 'Magyar',
  'hy-AM': 'Հայերեն',
  id: 'Bahasa Indonesia',
  'id-ID': 'Bahasa Indonesia',
  is: 'Íslenska',
  'is-IS': 'Íslenska (Iceland)',
  it: 'Italiano',
  'it-IT': 'Italiano',
  ja: '日本語',
  'ja-JP': '日本語',
  'jv-ID': 'Basa Jawa',
  'ka-GE': 'ქართული',
  kab: 'Taqbaylit',
  'kk-KZ': 'Қазақша',
  km: 'ភាសាខ្មែរ',
  'km-KH': 'ភាសាខ្មែរ',
  kn: 'ಕನ್ನಡ',
  'kn-IN': 'ಕನ್ನಡ (India)',
  ko: '한국어',
  'ko-KR': '한국어 (韩国)',
  'ku-TR': 'Kurdî',
  la: 'Latin',
  'la-VA': 'Latin',
  lb: 'Lëtzebuergesch',
  'li-NL': 'Lèmbörgs',
  lt: 'Lietuvių',
  'lt-LT': 'Lietuvių',
  lv: 'Latviešu',
  'lv-LV': 'Latviešu',
  mai: 'मैथिली, মৈথিলী',
  'mg-MG': 'Malagasy',
  mk: 'Македонски',
  'mk-MK': 'Македонски (Македонски)',
  ml: 'മലയാളം',
  'ml-IN': 'മലയാളം',
  'mn-MN': 'Монгол',
  mr: 'मराठी',
  'mr-IN': 'मराठी',
  ms: 'Bahasa Melayu',
  'ms-MY': 'Bahasa Melayu',
  mt: 'Malti',
  'mt-MT': 'Malti',
  my: 'ဗမာစကာ',
  nb: 'Norsk (bokmål)',
  'nb-NO': 'Norsk (bokmål)',
  ne: 'नेपाली',
  'ne-NP': 'नेपाली',
  nl: 'Nederlands',
  'nl-BE': 'Nederlands (België)',
  'nl-NL': 'Nederlands (Nederland)',
  'nn-NO': 'Norsk (nynorsk)',
  no: 'Norsk',
  oc: 'Occitan',
  'or-IN': 'ଓଡ଼ିଆ',
  pa: 'ਪੰਜਾਬੀ',
  'pa-IN': 'ਪੰਜਾਬੀ (ਭਾਰਤ ਨੂੰ)',
  pl: 'Polski',
  'pl-PL': 'Polski',
  'ps-AF': 'پښتو',
  pt: 'Português',
  'pt-BR': 'Português (Brasil)',
  'pt-PT': 'Português (Portugal)',
  'qu-PE': 'Qhichwa',
  'rm-CH': 'Rumantsch',
  ro: 'Română',
  'ro-RO': 'Română',
  ru: 'Русский',
  'ru-RU': 'Русский',
  'sa-IN': 'संस्कृतम्',
  'se-NO': 'Davvisámegiella',
  'si-LK': 'පළාත',
  sk: 'Slovenčina',
  'sk-SK': 'Slovenčina (Slovakia)',
  sl: 'Slovenščina',
  'sl-SI': 'Slovenščina',
  'so-SO': 'Soomaaliga',
  sq: 'Shqip',
  'sq-AL': 'Shqip',
  sr: 'Српски',
  'sr-RS': 'Српски (Serbia)',
  su: 'Basa Sunda',
  sv: 'Svenska',
  'sv-SE': 'Svenska',
  sw: 'Kiswahili',
  'sw-KE': 'Kiswahili',
  ta: 'தமிழ்',
  'ta-IN': 'தமிழ்',
  te: 'తెలుగు',
  'te-IN': 'తెలుగు',
  tg: 'забо́ни тоҷикӣ́',
  'tg-TJ': 'тоҷикӣ',
  th: 'ภาษาไทย',
  'th-TH': 'ภาษาไทย (ประเทศไทย)',
  tl: 'Filipino',
  'tl-PH': 'Filipino',
  tlh: 'tlhIngan-Hol',
  tr: 'Türkçe',
  'tr-TR': 'Türkçe',
  'tt-RU': 'татарча',
  uk: 'Українська',
  'uk-UA': 'Українська',
  ur: 'اردو',
  'ur-PK': 'اردو',
  uz: 'O\'zbek',
  'uz-UZ': 'O\'zbek',
  vi: 'Tiếng Việt',
  'vi-VN': 'Tiếng Việt',
  'xh-ZA': 'isiXhosa',
  yi: 'ייִדיש',
  'yi-DE': 'ייִדיש (German)',
  zh: '中文',
  'zh-CN': '中文（中国）',
  zh_CN: '中文（中国）',
  'zh-HK': '中文（香港）',
  'zh-Hans': '中文简体',
  'zh-Hant': '中文繁體',
  'zh-SG': '中文（新加坡）',
  'zh-TW': '中文（台灣）',
  zh_TW: '中文（台灣）',
  'zu-ZA': 'isiZulu',
};