import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IBaseLink } from '../../interfaces/interfaces';
import { LinkContent } from './LinkContent';

export const Link: React.FC<IBaseLink> = ({
  text, to, href, icon, onClick, className,
}) => (to
  ? (
    <RouterLink to={to} onClick={onClick} className={className}>
      <LinkContent icon={icon} text={text} />
    </RouterLink>
  ) : (
    <a href={href} onClick={onClick} target="_blank" rel="noopener noreferrer" className={className}>
      <LinkContent icon={icon} text={text} ext />
    </a>
  )
);