import clsx from 'clsx';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { langObserver } from '../LanguageProvider/eventObserver';
import { ILangContext, LangContext } from '../LanguageProvider/LangContext';
import { langmap } from '../LanguageProvider/langmap';
import { LangIcon } from './icons/LangIcon';
import './languageMenu.scss';

interface ILangageMenu {
  wide?: boolean
}

export const LanguageMenu: React.FC<ILangageMenu> = ({ wide }) => {
  const messages: ILangContext = useContext(LangContext);
  const intl = useIntl();
  const [menuOpen, setMenuOpen] = useState(false);
  const supportedLocales = useMemo(() => Object.keys(messages).sort((a, b) => a.localeCompare(b)).map((key) => ({ language: langmap[key], key })), [messages]);

  const onClickOutside = useCallback(() => {
    // console.log('onClickOutside');
    setMenuOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  useEffect(() => () => {
    window.removeEventListener('click', onClickOutside);
  }, [onClickOutside]);

  useEffect(() => {
    if (menuOpen) {
      // We need timeout here instead of stopPropagation event to close other submenus
      setTimeout(() => {
        window.addEventListener('click', onClickOutside);
      }, 50);
    } else {
      window.removeEventListener('click', onClickOutside);
    }
  }, [menuOpen, onClickOutside]);

  const handleLocaleClick = useCallback(({ currentTarget: { dataset } }) => {
    if (dataset.locale) {
      localStorage.setItem('dufLocale', dataset.locale);
      langObserver.emit('SET_LOCALE', dataset.locale);
      setMenuOpen(false);
    }
  }, []);

  return (
    <div className={clsx('duf-sidebar-language', { wide })}>
      <button type="button" onClick={handleClick} className={clsx({ menuOpen })}>
        <LangIcon />
        <span>
          {wide ? (langmap?.[intl.locale] || 'English') : <FormattedMessage id="language" defaultMessage="Language" />}
        </span>
      </button>
      <ul className={clsx('duf-sidebar-language-list', { menuOpen })}>
        {supportedLocales.map((locale) => (
          <li
            key={locale.key}
            data-locale={locale.key}
            onClick={handleLocaleClick}
            className={clsx({ active: intl.locale === locale.key })}
          >
            {locale.language}
          </li>
        ))}
      </ul>
    </div>
  );
};