declare global {
  interface Window {
    Intercom: (action: string, params: any) => void;
  }
}

let style: HTMLElement;

const css = `
  .intercom-launcher {
    width: 60px;
    height: 60px;
  }
`;

const cssMobile = `
  .intercom-launcher {
    width: 40px;
    height: 40px;
  }
  
  .intercom-launcher>div>svg {width: 18px;}
`;

const onResize = () => {
  if (window.innerWidth > 834) {
    style.innerHTML = css;
  } else {
    style.innerHTML = cssMobile;
  }
};

const injectStyles = () => {
  setTimeout(() => {
    const intercomFrame = document.querySelector('.intercom-launcher-frame');
    // @ts-ignore
    const intercom = intercomFrame.contentDocument;
    style = intercom.getElementById('duf-intercom-css');
    if (!style) {
      style = intercom.createElement('style');
      style.id = 'duf-intercom-css';
      style.innerHTML = window.innerWidth > 834 ? css : cssMobile;
      intercom.head.appendChild(style);
      window.addEventListener('resize', onResize);
    }
  }, 1000);
};

export const addIntercomListener = () => {
  if (window.Intercom) {
    window.Intercom('onHide', injectStyles);
    window.Intercom('onShow', injectStyles);
  }
};
