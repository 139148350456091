import React from 'react';
import { LinkIcon } from './icons/LinkIcon';

interface ILinkContent {
  text?: string
  icon?: string | React.ReactNode
  ext?: boolean
}

export const LinkContent: React.FC<ILinkContent> = ({ icon, text, ext }) => {
  let logo;
  if (icon) {
    if (typeof icon === 'string') {
      logo = <img src={icon} alt={text} />;
    } else {
      logo = icon;
    }
  }
  return (
    <>
      <figure>{logo}</figure>
      <span className="duf-sidebar-textbox">
        {text}
        {ext && <LinkIcon className="duf-sidebar-link-icon" />}
      </span>
    </>
  );
};
