import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ILink } from '../../interfaces/interfaces';
import MenuLink from './MenuLink';

import './styles.scss';

interface IContextMenu {
  links?: Array<ILink>
  rtl?: boolean
  profileImage?: string
}

export const ContextMenu: React.FC<IContextMenu> = ({ links = [], rtl, profileImage }) => {
  const [active, setActive] = useState(false);

  const handleClick = useCallback(() => {
    // event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    // setActive(!active);

    // We need timeout here instead of stopPropagation event to close other submenus
    setTimeout(() => setActive(!active), 50);
  }, [active, setActive]);

  const handleClickOutside = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="duf-contextMenu">
      <div className="duf-contextMenu-button" onClick={handleClick}>
        <div className={clsx('duf-contextMenu-buttonContent', { withImage: profileImage })}>
          {profileImage && <img src={profileImage} alt="profile" className="duf-contextMenu-profileImg" />}
          <div />
          <div />
          <div />
        </div>
        <div className={clsx('duf-contextMenu-glass', { active })} />
        <div className={clsx('duf-contextMenu-menu', { active, rtl })}>
          <ul>
            {links.map((link) => (
              <MenuLink key={`${link.id || link.to || link.href || link.onClick?.name}_${Math.random()}`} link={link} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};