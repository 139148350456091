import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from './Link';
import { LinkContent } from './LinkContent';
import { Arrow } from './icons/Arrow';
import { ILink } from '../../interfaces/interfaces';
import './sidebarLinks.scss';

interface ISidebarLinks {
  links?: Array<ILink>
  className?: string
  wide?: boolean
  bigIcons?: boolean
  topContent?: JSX.Element | JSX.Element[] // Extra top conten. (Usually could be used to show token balance)
  toggleMenu?: () => void;
}

export const SidebarLinks: React.FC<ISidebarLinks> = ({
  links, className, wide, bigIcons, topContent, toggleMenu,
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(null);
  const handleMenuClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

      const index = parseInt(event.currentTarget.dataset?.index, 10);
      if (index === expanded) {
        setExpanded(null);
      } else if (!wide && toggleMenu) {
        toggleMenu();
        setExpanded(index);
      } else {
        setExpanded(index);
      }
    },
    [expanded, setExpanded, wide, toggleMenu],
  );

  useEffect(() => {
    if (!wide) setExpanded(null);
  }, [wide]);
  return (
    <ul className={clsx('duf-sidebar-links', className, { wide })}>
      {topContent && <li className="aside-top-content">{topContent}</li>}
      {links.map((link, index) => {
        const key = `${link.id || link.to || link.href || link.onClick?.name}_${Math.random()}`;
        if (link.separator) {
          return <hr key={key} className="separator" />;
        }

        if (Array.isArray(link.items)) {
          return (
            <React.Fragment key={key}>
              <li
                className={clsx('duf-sidebar-link', {
                  active: location.pathname === link.to || expanded === index,
                  wide,
                  withItems: Array.isArray(link.items),
                  bigIcons,
                })}
              >
                <a data-index={index} onClick={handleMenuClick} className="dteuf-sidebar-link__withIms">
                  <LinkContent text={link.text} icon={link.icon} />
                  <Arrow className={clsx('duf-sidebar-arrow', { 'submenu-expanded': expanded === index })} />
                </a>
              </li>
              <ul className={clsx('duf-sidebar-submenu', { 'submenu-expanded': expanded === index, wide })}>
                {link.items.map((item) => (
                  <li key={`${item.id || item.to || item.href || item.onClick?.name}_${Math.random()}`} className={clsx('duf-sidebar-link', { wide, active: location.pathname === item.to })}>
                    <Link {...item} className={clsx(item.className, { active: location.pathname === item.to })} />
                  </li>
                ))}
              </ul>
            </React.Fragment>
          );
        }
        return (
          <li
            key={`${link.id || link.to || link.href || link.onClick?.name}_${Math.random()}`}
            className={clsx('duf-sidebar-link', {
              active: location.pathname === link.to || expanded === index,
              wide,
              withItems: Array.isArray(link.items),
              separator: link.separator,
              bigIcons,
            })}
          >
            {link.separator ? (
              <hr />
            ) : (
              <Link {...link} className={clsx(link.className, { active: location.pathname === link.to })} />
            )}
          </li>
        );
      })}
    </ul>
  );
};
