const eventObservers = Symbol('eventObservers');

export interface IEventObserverClass {
  /**
   * Add new item to the eventObservers list
   * @param eventObj - new EventObserver item
   */
  on: (eventObj: IEventObserverObject) => void

  /**
   * Pass nothing to remove all EventObservers
   * @param eventObj
   */
  off: (eventObj?: IEventObserverObject) => void

  /**
   * @param eventType - Name of the event
   * @param result - What should be pass to the callback?
   */
  emit: (eventType: string, result: any) => void
}

interface IEventObserverObject<CallbackInput = any, CallbackOutput = void> {
  // Name of the Event Observer
  eventType: string
  // Function that will be called for this Event Observer
  callback?: (result: CallbackInput) => CallbackOutput
}

class EventObserver implements IEventObserverClass {
  [eventObservers]: Array<IEventObserverObject>;

  constructor() {
    this[eventObservers] = [];
  }

  on(eventObj: IEventObserverObject) {
    this[eventObservers].push(eventObj);
  }

  off(event?: IEventObserverObject) {
    if (event) {
      if (event.callback) {
        this[eventObservers] = this[eventObservers].filter((observer) => observer.eventType !== event.eventType || observer.callback !== event.callback);
      } else {
        this[eventObservers] = this[eventObservers].filter((observer) => observer.eventType !== event.eventType);
      }
    } else {
      this[eventObservers] = [];
    }
  }

  emit<CallbackInputValue = any>(eventType: string, result: CallbackInputValue) {
    const observers = this[eventObservers].filter((eventObserver) => eventObserver.eventType === eventType);

    if (observers?.length) {
      observers.forEach((observer) => {
        observer.callback(result);
      });
    }
  }
}

export const langObserver = new EventObserver();
