import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from './Link';
import { ILink } from '../../interfaces/interfaces';

interface IHeaderLinks {
  links?: Array<ILink>
  className?: string
}

export const HeaderLinks: React.FC<IHeaderLinks> = ({ links, className }) => {
  const location = useLocation();
  return !!links?.length && (
    <nav className={clsx('duf-header-links', className)}>
      {links.map((link) => (
        <Link key={`${link.id || link.to || link.href || link.onClick?.name}_${Math.random()}`} {...link} className={clsx(link.className, { active: location.pathname === link.to })} />
      ))}
    </nav>
  );
};