import { createContext } from 'react';
import { MessageFormatElement } from 'react-intl';
import { langmap } from './langmap';

export type TLanguage = (typeof langmap)[keyof typeof langmap];

export type GetLocale = () => Promise<Record<string, string> | Record<string, MessageFormatElement[]>>;

export interface ILangContext {
  [key: TLanguage]: GetLocale
}

export const LangContext = createContext<ILangContext>({});