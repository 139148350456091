import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ILink } from '../../interfaces/interfaces';

interface IMenuLink {
  link: ILink
}

const MenuLink: React.FC<IMenuLink> = ({ link }) => {
  const content = useMemo(() => (
    <>
      <div className="duf-contextMenu-logoWrapper">
        {!!link.icon && (typeof link.icon === 'string' ? <img src={link.icon} alt={link.text} /> : link.icon)}
      </div>
      {link.text}
    </>
  ), [link]);
  return (
    <li>
      {link.to ? (
        <Link to={link.to} onClick={link.onClick} className={link.className}>
          {content}
        </Link>
      ) : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link.href}
          onClick={link.onClick}
          className={link.className}
        >
          {content}
        </a>
      )}
    </li>
  );
};

export default MenuLink;
