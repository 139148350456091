import React, {
  useCallback, useState, useEffect, MutableRefObject,
} from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { NetworkSelector } from '../NetworkSelector/NetworkSelector';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { ILink, INetworkSelector } from '../../interfaces/interfaces';
import { Link } from './Link';
import { HeaderLinks } from './HeaderLinks';
import { addIntercomListener } from './intercom';
import { Control } from './icons/Control';
import { LanguageMenu } from './LanguageMenu';

import './header.scss';
import './sidebar.scss';
import './intercom.scss';
import { SidebarLinks } from './SidebarLinks';
import { HowIcon } from './icons/HowIcon';
import { breakpoints } from '../../constants/breakpoints';
import { ShieldIcon } from './icons/ShieldIcon';
import { DefillamaIcon } from './icons/DefillamaIcon';

const voidCallback = () => { };

interface ILogo {
  img: string | React.ReactNode
  imgWide?: string | React.ReactNode
  to?: string
  href?: string
  text?: string
  onClick?: () => void
}

type TVoidFunction = () => void;

interface IHeader {
  className?: string
  profileImage?: string
  logo: ILogo // app logo
  projectLogo: ILogo // for farm page
  links?: Array<ILink>
  sidebarLinks?: Array<ILink>
  sidebarAdditionalLinks?: Array<ILink>
  menuLinks?: Array<ILink>
  mobileLinks?: Array<ILink>
  networkSelectorProps?: INetworkSelector
  backButton?: React.ReactNode
  children?: React.ReactNode
  walletComponent?: React.ReactNode
  onCloseCallbackRef?: MutableRefObject<TVoidFunction>
  howToLink?: string
  defillamaLink?: string
  isExpanded?: boolean
  topContent?: JSX.Element | JSX.Element[]
  onToggleMenu?: (e?: boolean) => void,
  onAuditsClicked?: () => void,
}

export const Header: React.FC<IHeader> = ({
  className,
  profileImage,
  logo,
  projectLogo,
  links = [],
  sidebarLinks = [],
  sidebarAdditionalLinks = [],
  menuLinks = [],
  mobileLinks,
  networkSelectorProps,
  backButton,
  children,
  walletComponent,
  onCloseCallbackRef,
  howToLink,
  defillamaLink,
  isExpanded,
  topContent,
  onToggleMenu,
  onAuditsClicked,
}) => {
  const [checked, setChecked] = useState(false);
  const [domMounted, setDomMounted] = useState(false);
  const [expanded, setExpanded] = useState(isExpanded);
  const { pathname } = useLocation();

  const toggleMenu = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  useEffect(() => {
    if (onToggleMenu) { onToggleMenu(expanded); }
  }, [expanded, setExpanded]);

  const closeMenu = useCallback(() => setChecked(false), [setChecked]);

  const handleClick = useCallback((event) => {
    // console.log(event.target, event.currentTarget);
    // event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }, []);

  useEffect(() => {
    if (onCloseCallbackRef) {
      onCloseCallbackRef.current = closeMenu;
    }
  }, [onCloseCallbackRef, closeMenu]);

  useEffect(() => {
    addIntercomListener();
  }, []);

  useEffect(() => {
    const { innerWidth } = window;
    const isTablet = innerWidth < breakpoints.tablet;
    if (expanded && isTablet && domMounted) toggleMenu(); // domMounted is added to prevent toggling the menu on first render
    setDomMounted(true);
  }, [pathname]);

  const injectCLSX = useCallback((...clsxParams) => clsx(...clsxParams, { 'sidebar-expanded': expanded }), [expanded]);
  return (
    <>
      <header className={clsx('duf-header', className, expanded && 'sidebar-expanded')}>
        <div className="duf-aside-backdrop" onClick={toggleMenu} />
        <div className="duf-header-container">
          {backButton}
          <div className={clsx('duf-header-logo', expanded && 'sidebar-expanded')}>
            <Link className="duf-header-logo-icon" icon={projectLogo?.img} href={projectLogo?.href} onClick={projectLogo?.onClick} to={projectLogo?.to} />
            {/* <span className="duf-header-logo-text">{projectLogo?.text}</span> */}
            <Link className="wide-logo" icon={projectLogo?.img || logo?.imgWide} href={projectLogo?.href || logo?.href} onClick={logo?.onClick} to={projectLogo?.to || logo?.to} />
          </div>

          <input className="duf-header-menu-btn" type="checkbox" id="menu-btn" checked={expanded} onChange={voidCallback} />
          <label className="duf-header-menu-icon" htmlFor="menu-btn" onClick={toggleMenu}><span className="duf-header-navicon" /></label>
          <HeaderLinks links={mobileLinks} className="dub-header-mobile-links" />

          <div className={clsx('duf-header-wrapper__right', { checked })}>
            <HeaderLinks links={links} />
            {children}
          </div>

          {networkSelectorProps && <NetworkSelector rtl {...networkSelectorProps} />}
          <div className="duf-header-wallet-container">
            {walletComponent}
            <ContextMenu links={menuLinks} rtl profileImage={profileImage} />
          </div>
        </div>
      </header>

      <aside className={injectCLSX('duf-aside', className)} onClick={handleClick}>
        <div className={injectCLSX('duf-aside-container')}>
          {backButton}
          <div className={injectCLSX('duf-aside-logo', { noPadding: !!backButton })}>
            {/* <Link icon={logo.img} href={logo.href} onClick={logo.onClick} to={logo.to} /> */}
            <Link icon={expanded ? logo?.imgWide : logo?.img} href={logo?.href} onClick={logo?.onClick} to={logo?.to} />
            {/* {expanded && <span className="duf-aside-logo-text">{logo.text}</span>} */}
          </div>
          <div className={injectCLSX('duf-aside-control-wrapper', className)}>
            <span className="duf-aside-control" style={{ color: '#fff' }} onClick={toggleMenu}>
              <Control />
            </span>
          </div>
          <div className="duf-aside-scroll-container">
            <div className={injectCLSX('duf-aside-top-content')}>
              <SidebarLinks links={sidebarLinks} wide={expanded} topContent={topContent} toggleMenu={toggleMenu} />
            </div>
            <div className={injectCLSX('duf-aside-bottom-content')}>
              <SidebarLinks links={sidebarAdditionalLinks} wide={expanded} bigIcons />
              <div className={injectCLSX('duf-aside-row-items-wrapper')}>
                {defillamaLink && (
                  <a href={defillamaLink} target="_blank" rel="noreferrer noopener" className={injectCLSX('duf-aside-item defilama', { wide: expanded })}>
                    <DefillamaIcon />
                    <span>
                      <FormattedMessage id="defilama" defaultMessage="Defilama" />
                    </span>
                  </a>
                )}
                {onAuditsClicked && typeof (onAuditsClicked) === 'function' && (
                  <button type="button" className={injectCLSX('duf-aside-item', { wide: expanded })} onClick={onAuditsClicked}>
                    <ShieldIcon />
                    <span>
                      <FormattedMessage id="audits" defaultMessage="Audits" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="duf-aside-language-wrapper">
            {howToLink && (
              <a className={clsx('duf-aside-howto', { wide: expanded })} href={howToLink} target="_blank" rel="noreferrer noopener">
                <HowIcon />
                <span>
                  <FormattedMessage id="how_it_works" defaultMessage="How it Works" />
                </span>
              </a>
            )}
            <LanguageMenu wide={expanded} />
          </div>
        </div>
      </aside>
    </>
  );
};
