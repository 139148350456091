import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { useComponentWillMount } from './useComponentWillMount';
import { langObserver } from './eventObserver';
import { ILangContext, LangContext } from './LangContext';

interface ILangProvider {
  children: React.ReactNode
  // enMessages: Record<string, string> | Record<string, MessageFormatElement[]>
  locales: ILangContext
  onChangeLocale?: (locale: string, messages: Record<string, string> | Record<string, MessageFormatElement[]>) => void
}

const userLocale = localStorage.getItem('dufLocale') || 'en';

export const LangProvider = forwardRef<any, ILangProvider>(({
  children, locales, onChangeLocale, ...intlProps
}, ref) => {
  const [locale, setLocale] = useState(userLocale);
  const [messages, setMessages] = useState(null);
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    changeLocale(newLocale: string) {
      langObserver.emit('SET_LOCALE', newLocale);
    },
  }));

  const changeLocale = useCallback((newLocale) => {
    locales[newLocale]?.().then((localeMessages) => {
      setMessages(localeMessages);
      setLocale(newLocale);
      if (typeof onChangeLocale === 'function') {
        onChangeLocale(newLocale, localeMessages);
      }
    });
  }, [locales, setLocale, setMessages, onChangeLocale]);

  const setInitialLocale = useCallback(() => {
    const localeFn = locales?.[locale] || locales?.en;
    if (!localeFn) {
      setError(`Missing locale ${locale}`);
      throw new Error(`Missing locale ${locale}`);
    }
    localeFn().then(setMessages);
  }, [locales, locale]);

  useComponentWillMount(setInitialLocale);

  useEffect(() => {
    langObserver.off({ eventType: 'SET_LOCALE' });
    langObserver.on({ eventType: 'SET_LOCALE', callback: changeLocale });
    return () => {
      langObserver.off();
    };
  }, [changeLocale]);

  if (error) {
    return (
      <h4>{error}</h4>
    );
  }

  return messages && (
    <IntlProvider locale={locale} messages={messages} {...intlProps}>
      <LangContext.Provider value={locales}>
        {children}
      </LangContext.Provider>
    </IntlProvider>
  );
});
