import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { allNetworks, BLOCKCHAIN_TYPES } from './networks';
import link from './icons/link.svg';
// import arrow from './icons/arrow_down.svg';
import './styles.scss';
import '../styles/duf.scss';
import { INetwork, INetworkSelector } from '../../interfaces/interfaces';

const removeEnvFromNet = (networkName: string) => {
  if (/(testnet|mainnet)/gi.test(networkName)) {
    if (networkName.includes('Mainnet')) {
      return networkName.split(' Mainnet')[0];
    }
    return networkName.split(' Testnet')[0];
  }
  return networkName;
};

const getABRV = (networkName: string) => {
  const words = networkName.split(' ');
  if (words.length > 1) {
    return words.map((word) => word[0].toUpperCase());
  }
  return networkName;
};

const getShortName = (networkName: string) => {
  if (!networkName) {
    return networkName;
  }
  const name = removeEnvFromNet(networkName);
  const words = name.split(' ');
  if (name.length > 15) {
    return words.length > 2 ? getABRV(name) : name.split(' ')[0];
  }
  return name;
};

export const NetworkSelector: React.FC<INetworkSelector> = ({
  networkId, networks, onNetworkSelect, account, disabled, rtl, blockchainType = BLOCKCHAIN_TYPES.evm,
}) => {
  const [active, setActive] = useState(false);
  const selectedNetwork = useMemo(() => {
    const net: INetwork | any = networks?.find((network) => (networkId === network.networkId && (!network.blockchainType || network.blockchainType === blockchainType))) || networks?.[0] || {};
    const id = Number.isFinite(networkId) ? networkId : networks?.[0]?.networkId;
    return { ...allNetworks[blockchainType]?.[id], ...net };
  }, [networkId, networks, blockchainType]);
  const handleClick = useCallback(() => {
    if (onNetworkSelect && !disabled) {
      // event.stopPropagation();
      // event.nativeEvent.stopImmediatePropagation();
      // setActive(!active);

      // We need timeout here instead of stopPropagation event to close other submenus
      setTimeout(() => setActive(!active), 50);
    }
  }, [active, setActive, onNetworkSelect, disabled]);

  const handleNetworkClick = useCallback((event) => {
    // event.stopPropagation();
    const { currentTarget: { dataset } } = event;
    if (onNetworkSelect && !disabled) {
      onNetworkSelect(parseInt(dataset.net, 10), dataset.blockchain);
    }
  }, [onNetworkSelect, disabled]);

  const handleClickOutside = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useEffect(() => {
    if (disabled) {
      document.removeEventListener('click', handleClickOutside);
    } else {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [disabled, handleClickOutside]);

  if (!networks.length) {
    return null;
  }

  return (
    <div className="duf-networkSelector">
      <div className="duf-networkSelector-button">
        <div className="duf-networkSelector-buttonContent" onClick={handleClick}>
          {selectedNetwork?.btnLogo ? <img src={selectedNetwork?.btnLogo} alt="" /> : selectedNetwork?.logo && (<img src={selectedNetwork.logo} alt="" />)}
          <span className="duf-networkSelector-chainName">
            {getShortName(selectedNetwork?.chainName)}
          </span>
          <span className={clsx('duf-networkSelector-arrow', { active })}>
            {/*
            {!!onNetworkSelect && !disabled && <img src={arrow} alt="arrow" />}
            */}
          </span>
        </div>
        <div className={clsx('duf-networkSelector-glass', { active })} />
        <div className={clsx('duf-networkSelector-menu', { active, rtl })}>
          <ul>
            <li>
              <FormattedMessage id="select_chain" defaultMessage="Select a chain" />
            </li>
            {networks?.map((network) => {
              const bType = network.blockchainType || BLOCKCHAIN_TYPES.evm;
              return (
                <li
                  key={`${network.networkId}${bType}`}
                  data-net={network.networkId}
                  data-blockchain={bType}
                  onClick={handleNetworkClick}
                  className={clsx({ active: networkId === network.networkId, connected: account })}
                >
                  <div className="duf-networkSelector-logoWrapper">
                    {(network.logo || allNetworks[bType]?.[network.networkId]?.logo) && (
                      <img src={network.logo || allNetworks[bType]?.[network.networkId]?.logo} alt="" />
                    )}
                  </div>
                  <div className="duf-networkSelector-menuContent">
                    {getShortName(allNetworks[bType]?.[network.networkId]?.chainName)}
                    {account && networkId === network.networkId && !!selectedNetwork?.blockExplorerUrls?.[0] && (
                      <div className="duf-networkSelector-menuItem-netScan">
                        <a href={`${selectedNetwork?.blockExplorerUrls?.[0]}/address/${account}`} target="_blank" rel="noopener noreferrer">
                          {selectedNetwork?.scanName || 'Blockchainscan'}
                          <img src={link} alt="" />
                        </a>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
