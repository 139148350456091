import React from 'react';
import { IIcon } from '../../../interfaces/interfaces';

export const ShieldIcon: React.FC<IIcon> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.3221 2.3225C12.3872 2.35343 12.4486 2.39417 12.504 2.44459C14.693 4.37789 17.548 5.3843 20.4657 5.25079C20.8108 5.235 21.1221 5.45702 21.2195 5.78849C21.7015 7.42794 21.8489 9.14754 21.6531 10.8451C21.4574 12.5427 20.9223 14.1836 20.0799 15.6703C19.2374 17.157 18.1047 18.4592 16.749 19.4995C15.3933 20.5398 13.8423 21.2969 12.1883 21.726C12.0648 21.758 11.9351 21.758 11.8117 21.726C10.1576 21.2969 8.6066 20.5398 7.25092 19.4995C5.89524 18.4592 4.76251 17.157 3.92004 15.6703C3.07756 14.1836 2.54254 12.5427 2.34677 10.8451C2.151 9.14754 2.29846 7.42794 2.7804 5.78849C2.87784 5.45702 3.18911 5.235 3.53424 5.25079C6.45188 5.3843 9.30695 4.37789 11.4959 2.44459C11.5513 2.39417 11.6127 2.35343 11.6778 2.32251C11.7798 2.27395 11.89 2.24996 12 2.25C12.1099 2.24996 12.2201 2.27395 12.3221 2.3225ZM4.0726 6.76254C6.95367 6.7572 9.7485 5.77569 12 3.97936C14.2514 5.77569 17.0462 6.7572 19.9273 6.76254C20.2337 8.04086 20.314 9.36448 20.163 10.6733C19.9903 12.1711 19.5182 13.619 18.7748 14.9308C18.0315 16.2426 17.032 17.3916 15.8358 18.3095C14.6912 19.1878 13.3888 19.8374 12 20.2234C10.6111 19.8374 9.30869 19.1878 8.16408 18.3095C6.96789 17.3916 5.96843 16.2426 5.22507 14.9308C4.48171 13.619 4.00964 12.1711 3.83689 10.6733C3.68596 9.36448 3.76624 8.04086 4.0726 6.76254ZM15.5303 10.5303C15.8232 10.2374 15.8232 9.76257 15.5303 9.46968C15.2374 9.17679 14.7626 9.17679 14.4697 9.46968L11 12.9393L9.53033 11.4697C9.23744 11.1768 8.76256 11.1768 8.46967 11.4697C8.17678 11.7626 8.17678 12.2374 8.46967 12.5303L10.4697 14.5303C10.7626 14.8232 11.2374 14.8232 11.5303 14.5303L15.5303 10.5303Z" fill="rgba(235, 235, 245, 0.6)" />
  </svg>
);


